import Cookies from 'cookies-js'

const MODE_COOKIE_NAME = 'theme_mode'
const DISPLAY_COOKIE_NAME = 'theme_mode_cookie'
const CLOSE_STATUS = 'close'
const OPEN_CLASS = 'open'
const CALLOUT = '.js-callout'
const THEME_MODES = { DARK: 'is-dark-mode', LIGHT: 'is-light-mode' }

class ThemeMode extends HTMLElement {
  constructor() {
    super()

    this.callout = this.querySelector(CALLOUT)
    this.durationDisplay = this.dataset.duration * 1 * 1000
    this.themeMode = THEME_MODES.LIGHT
    this.dataCookies = Number(this.dataset.cookies) > 0 ? 60 * 60 * 24 * Number(this.dataset.cookies) : 1
    this.getSavedMode()
    this.attachBtnEvents()
    
    if (this.callout) {
      this.setDisplayCallout(this.callout, this.durationDisplay)
    }
  }
  
  setDisplayCallout (callout, timeout) {
    const hasCookie = Cookies.get(DISPLAY_COOKIE_NAME) ? Cookies.get(DISPLAY_COOKIE_NAME) === CLOSE_STATUS : false
    
    if (hasCookie) return

    !this.callout.classList.contains(OPEN_CLASS) && this.callout.classList.add(OPEN_CLASS)
  }
  
  closeCallout () {
    this.callout.classList.contains(OPEN_CLASS) && this.callout.classList.remove(OPEN_CLASS)
    Cookies.set(DISPLAY_COOKIE_NAME, CLOSE_STATUS, { expires: this.dataCookies })
  }

  attachBtnEvents() {
    this.lightModeBtn = this.querySelector('.js-light-mode')
    this.darkModeBtn = this.querySelector('.js-dark-mode')

    this.lightModeBtn.addEventListener('click', () => {
      setTimeout(() => {
        this.setMode(THEME_MODES.LIGHT)
      }, 200)
    })

    this.darkModeBtn.addEventListener('click', () => {
      setTimeout(() => {
        this.setMode(THEME_MODES.DARK)
      }, 200)
    })
  }

  setMode(mode = THEME_MODES.LIGHT) {
    const oldMode = this.themeMode

    this.themeMode = mode

    document.body.classList.remove(this.isDarkMode() ? THEME_MODES.LIGHT : THEME_MODES.DARK)
    document.body.classList.add(this.themeMode)

    window.isDarkMode = this.isDarkMode()

    if (oldMode !== mode) {
      Cookies.set(MODE_COOKIE_NAME, mode, { expires: this.dataCookies })
      document.dispatchEvent(new CustomEvent('theme-mode-changed', { detail: this.themeMode }))
    }
  }

  getSavedMode() {
    const savedMode = Cookies.get(MODE_COOKIE_NAME) || THEME_MODES.DARK
    this.setMode(savedMode)

    // Set theme modes default load page
    setTimeout(() => {
      const callouts = document.querySelectorAll('catch-callout')
      const theme = savedMode === THEME_MODES.LIGHT ? 'light-color' : 'dark-color'

      callouts && callouts.forEach(callout => {
        callout.setAttribute('theme', theme)
      })
    }, 200)
  }

  isDarkMode() {
    return this.themeMode === THEME_MODES.DARK
  }
}

customElements.define('theme-mode', ThemeMode)
